<template>
  <rpm-layout>
    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2 space-x-1">
      <div class="relative w-1/4">
        <RpmSearch v-model="search"></RpmSearch>
      </div>
      <div>
        <el-select v-model="client" value-key="id" clearable placeholder="Client" filterable>
          <el-option v-for="client in clients" :value="client" :label="client.name" :key="client.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="health_coach" value-key="id" clearable placeholder="Health Coach" filterable>
          <el-option v-for="health_coach in healthCoaches" :value="health_coach" :label="`${health_coach.first_name} ${health_coach.last_name}`" :key="health_coach.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="pcp" value-key="id" clearable placeholder="PCP" filterable>
          <el-option v-for="pcp in pcps" :value="pcp" :label="`${pcp.first_name} ${pcp.last_name}`" :key="pcp.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="status" clearable placeholder="Status">
          <el-option v-for="status in statuses" :value="status.status" :key="status.status">
            <el-tooltip :content="status.description" placement="top">
              <span>{{status.status}}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="info" @click="refresh">Refresh</el-button>
<!--        <button type="button" @click="refresh"-->
<!--                class="py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-blue-200 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">-->
<!--          Refresh-->
<!--        </button>-->
      </div>
    </div>
    <div class="relative overflow-x-auto sm:rounded-lg">
      <v-data-table :loading="loading" :headers="dtHeaders" :items="dtData" @click:row="goToPatient($event.id)" :search="search" :sort-desc="['alert_order']" :sort-by="['alert_order', 'alert_date']" multi-sort class="v-data-table cursor-pointer">
        <template v-slot:loading><v-progress-circular indeterminate></v-progress-circular></template>
        <template v-slot:item.alert_date="{ item }">
          {{ item.alert_date | datetime }}
        </template>
        <template v-slot:item.alert_order="{ item }">
          <svg :color="item.alert_level" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="cursor-pointer w-6 h-6 inline mr-1">
            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
          </svg>
          <v-tooltip right v-for="(alert, type) in item.alerts" :key="type">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" :color="alertColor(type)" small class="capitalize ml-1">{{alertName(type)}}</v-chip>
            </template>
            <span>{{alertDescriptions(type)}}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <button @click.stop="goToPatient(item.id, true)"><v-icon small>mdi-open-in-new</v-icon></button>
          {{ item.name }}
        </template>
      </v-data-table>
    </div>
  </rpm-layout>
</template>

<script>
// import Vue from "vue";
import moment from "moment-timezone";
import RpmLayout from "../../layouts/RpmLayout.vue";
import RpmSearch from "@/components/RpmSearch.vue";
import PatientMixin from "@/mixins/Patient";
import Template from "@/mixins/Template";
import statuses from "@/helpers/patientStatuses";

export default {
  mixins: [PatientMixin],
  components: {Template, RpmSearch, RpmLayout},
  data() {
    return {
      loading: true,
      search: "",
      client: null,
      pcp: null,
      health_coach: null,
      status: null,
      statuses: statuses,
    };
  },
  created: async function () {
    await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser').then(this.resetFilters) : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
    ]).finally(() => {
      this.loading = false;
      this.resetFilters();
    });
  },
  mounted: function () {
  },
  computed: {
    // ...mapState(["patients"]),
    authUser() {
      return this.$store.state.patients.authUser
    },
    clients() {
      return this.authUser.available_clients;
    },
    healthCoaches() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach') && !p.disabled);
    },
    pcps() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_provider') && !p.disabled);
    },
    patients() {
      return this.$store.state.patients.patients.filter((p) => p.cache.alerts && Object.keys(p.cache.alerts).length);
    },
    patientsFiltered() {
      let patients = this.patients;
      if (this.client) {
        patients = patients.filter((p) => p.primary_client_id === this.client.id);
      }
      if (this.health_coach) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.care_manager === this.health_coach.id);
      }
      if (this.pcp) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.pcp === this.pcp.id);
      }
      if (this.status) {
        patients = patients.filter((p) => p.status === this.status);
      }
      return patients;
    },
    dtHeaders() {
      return [
        {text: 'Alert Type', value: 'alert_order'},
        // {text: 'Alert Type', value: 'alert_type'},
        {text: 'Date', value: 'alert_date'},
        {text: 'Name', value: 'name'},
        {text: 'Client', value: 'client'},
        {text: 'PCP', value: 'pcp'},
        {text: 'Health Coach', value: 'health_coach'},
      ]
    },
    dtData() {
      return this.patientsFiltered.map((patient) => ({
        id: patient.id,
        alert_date: Object.values(patient.cache.alerts).sort((a,b) => moment(a).valueOf() - moment(b).valueOf())[0],
        // alert_color: patient.cache.alerts.vital || patient.cache.alerts.rpm_dur_red || patient.cache.alerts.ccm_dur_red  ? 'red' : 'yellow',
        name: `${patient.first_name} ${patient.last_name}`,
        client: this.$store.getters.clientName(patient.primary_client_id),
        pcp: this.pcpName(patient),
        health_coach: this.healthCoachName(patient),
        // alerts: Object.keys(patient.cache.alerts).map(a => a.replaceAll('_', ' ').replaceAll('dur', 'task duration low')),
        alert_level: this.alertLevel(patient.cache.alerts),
        alert_order: this.alertOrder(patient.cache.alerts),
        alerts: patient.cache && patient.cache ? patient.cache.alerts : {},
      }))
    },
  },
  methods: {
    alertColor(type) {
      if (type === 'vital') return 'red';
      if (type === 'vital_warning') return 'yellow';
      if (type === 'task') return 'green';
      if (type === 'rpm_dur_red' || type === 'ccm_dur_red') return 'blue';
      if (type === 'no_recent_vitals') return 'orange';
      if (type === 'rpm_dur_yellow' || type === 'ccm_dur_yellow') return 'light-blue';
    },
    alertLevel(alerts) {
      if (alerts.vital) return 'red';
      if (alerts.vital_warning) return 'yellow';
      if (alerts.task) return 'green';
      if (alerts.rpm_dur_red || alerts.ccm_dur_red) return 'dodgerblue';
      if (alerts.no_recent_vitals) return 'orange';
      if (alerts.rpm_dur_yellow || alerts.ccm_dur_yellow) return 'cornflowerblue';
      return '';
    },
    alertOrder(alerts) {
      if (alerts.vital) return 100;
      if (alerts.vital_warning) return 90;
      if (alerts.task) return 80;
      if (alerts.rpm_dur_red || alerts.ccm_dur_red) return 70;
      if (alerts.no_recent_vitals) return 65;
      if (alerts.rpm_dur_yellow || alerts.ccm_dur_yellow) return 60;
      return 0;
    },
    alertName(name) {
      const names = {
        'rpm_dur_red': 'task duration',
        'ccm_dur_red': 'task duration',
        'rpm_dur_yellow': 'task duration',
        'ccm_dur_yellow': 'task duration',
        'vital': 'vital',
        'vital_warning': 'vital',
        'task': 'task',
        'no_recent_vitals': 'NRR',
      };
      return names[name];
    },
    alertDescriptions(name) {
      const descriptions = {
        'rpm_dur_red': 'Complete rpm notes with durations to clear this alert',
        'ccm_dur_red': 'Complete ccm notes with durations to clear this alert',
        'rpm_dur_yellow': 'Complete rpm notes with durations to clear this alert',
        'ccm_dur_yellow': 'Complete ccm notes with durations to clear this alert',
        'vital': 'Resolve all vital alerts to clear this alert',
        'vital_warning': 'Resolve all vital alerts to clear this alert',
        'task': 'Close all open tasks/notes to clear this alert',
        'no_recent_vitals': 'No recent reading (within 5 days)',
      };
      return descriptions[name];
    },
    resetFilters() {
      this.status = 'active';
      if (this.authUser.behaviors.includes('is_provider') && this.pcps.some(pcp => pcp.id === this.authUser.id)) {
        this.pcp = this.authUser;
      } else if (this.healthCoaches.some(hc => hc.id === this.authUser.id)) {
        this.health_coach = this.authUser;
      }
    },
    getProvider(id) {
      return this.$store.state.patients.providers.find((p) => p.id === id);
    },
    getProviderName(id) {
      const p = this.getProvider(id);
      return p ? p.first_name + " " + p.last_name : "None";
    },
    pcpName(patient) {
      return patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.pcp) : '';
    },
    healthCoachName(patient) {
      return patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.care_manager) : '';
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch('getPatients').finally(() => this.loading = false);
    },
  },
};
</script>
